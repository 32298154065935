import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';

import {
    Layout
} from '../components/common';

import { TelegramChannel } from '../components/common/Promo'

import {
    Container,
    Title,
    PostList,
    PostListItem,
    Date,
    SubHeading,
    Preview,
    Content,
    Thumbnail,
    Text,
} from '../pageStyles/AllPostStyle';

const BlogCategory = ({ data, pageContext }) => {
    const posts = data.allMarkdownRemark.edges;
    return (<Layout promotion={<TelegramChannel/>}>
            <Helmet>
                <title>{pageContext.category}</title>
                <meta name="description" content="Tutti gli articoli di NorthFPV"/>
            </Helmet>
            <Container>
            <Title>
                {pageContext.level}
            </Title>
            <PostList>
                {posts.map(( node, index ) => {
                    const { node : { frontmatter : { title, date, excerpt, author, ogimage}, fields : { slug } } } = node;
                    return (
                      <PostListItem>
                      <Link to={`${slug}`} key={index}>
                        <Content>
                          <Thumbnail src={ogimage ? ogimage : '/images/logo.png'}/>
                          <Text>
                            <Date>
                                {date} &nbsp;&middot;&nbsp; @{author}
                            </Date>
                            <SubHeading>
                                {title}
                            </SubHeading>
                            <Preview>
                                {excerpt}
                            </Preview>
                          </Text>
                      </Content>
                    </Link>
                  </PostListItem>
                    )
                })}
            </PostList>
        </Container>
        </Layout>   )
}
export default BlogCategory

export const query = graphql`
  query blogPostsListByLevel($level: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { level: { in: [$level] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale:"it")
            title
            excerpt
            author
            ogimage
          }
        }
      }
    }
  }
`